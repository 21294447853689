<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Invoices'">
      <template v-slot:right-end>
        <div class="vx-row my-5 mx-3 flex" style="place-items: end;">

          <div class="vx-col">

            <vs-button
              v-if="activeUserInfo.account_role < 2 && activeUserInfo.internal_role == 0"
              @click="openAddReclamationInvoice"
              type="filled" icon-pack="feather"
              icon="icon-plus">{{ $t('GenerateReclamationInvoice') }}
            </vs-button>
          </div>
          <div class="vx-col">

            <vs-button
              v-if="activeUserInfo.account_role < 2 && activeUserInfo.internal_role == 0"
              @click="openAddInvoice"
              type="filled" icon-pack="feather"
              icon="icon-plus">{{ $t('GenerateInvoice') }}
            </vs-button>
          </div>
          <div class="vx-col">

            <vs-button
              v-if="activeUserInfo.account_role < 2"
              @click="performDownload"
              type="filled">{{ $t('DownloadCSV') }}
            </vs-button>
          </div>
        </div>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vx-row">
        <div class=" w-full vx-col md:w-1/5 no-print">
          <vx-card>
            <div class="vx-col"
                 v-if="activeUserInfo.account_role != 2"
            >
              <dynamic-customers-drop-down
                :show-clear="true"
                :placeholder="$t('SearchCustomer')"
                :selected-item="customer || {}"
                @on-item-selected="onCustomerChanged($event)">
              </dynamic-customers-drop-down>
            </div>
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')" v-model="fromDate"
                        :disabled-dates="{from: new Date()}"
                        v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')" v-model="toDate"
                        :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>

            <div class="py-4"></div>
            <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{ $t('Filter').toUpperCase() }}
            </vs-button>
            <div class="py-2"></div>
            <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}
            </vs-button>
          </vx-card>
        </div>
        <div class="w-full md:w-4/5">
          <!-- Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="invoices.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="invoices.length > 0 && !errorFetching" ref="table"
                             :max-items="itemsPerPage"
                             :data="invoices">
            <template slot="thead">
              <vs-th>{{ $t('InvoiceDate') }}</vs-th>
              <vs-th>{{ $t('CustomerID') }}</vs-th>
              <vs-th>{{ $t('Organisation') }}</vs-th>
              <vs-th>{{ $t('InvoiceNo') }}</vs-th>
              <vs-th>{{ $t('CreditNoteNo') }}</vs-th>
              <vs-th>{{ $t('InvoiceValue') }}</vs-th>
              <vs-th>{{ $t('CreditNoteValue') }}</vs-th>
              <vs-th>{{ $t('Tax') }}</vs-th>
              <vs-th>{{ $t('Action') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate"
                     style="max-width: 100px;white-space: normal !important;padding: 0 8px;"
                  >{{ tr.invoice_date | onlyDateFormat }}</p>
                </vs-td>
                <vs-td>
                  <div class="vs-col">
                    <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>
                    <p class="product-name font-medium truncate">
                      {{ tr.organisation_name | capitalize }}</p>
                  </div>
                </vs-td>

                <vs-td>
                  <div class="vs-col">
                    <p class="truncate">{{ tr.organisation_first_name }}
                      {{ tr.organisation_last_name }}</p>
                    <p style="font-size: 11px;">
                      {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                    </p>
                    <p style="font-size: 11px; ">
                      {{ tr.postal }} {{ tr.city }}
                    </p>
                  </div>
                </vs-td>


                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.invoice_no }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.credit_note_no }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{
                      tr.invoice_value | germanNumberFormat
                    }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">
                    {{ tr.credit_note_value | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.tax }}</p>
                </vs-td>

                <vs-td class="whitespace-no-wrap vx-row">

                  <a
                    v-if="tr.invoice_pdf"
                    class="bg-primary px-4 py-2 text-white visited:text-white hover:text-white cursor-pointer rounded"
                    :href="tr.invoice_pdf" :download="tr.invoice_file_name">
                    {{ $t("Download") }}
                  </a>
                  <template v-else>
                    <vs-button class="" @click="openInvoice(1, tr.invoice_id)">{{ $t('Gross') }}</vs-button>
                    <!--              <div class="px-2"></div>-->
                    <vs-button class="ml-2" @click="openInvoice(2, tr.invoice_id)">{{ $t('CreditNote') }}
                    </vs-button>
                  </template>

                  <a
                    v-if="tr.invoice_pdf && activeUserInfo.account_role != 2"
                    class="bg-primary ml-2 px-4 py-2 text-white visited:text-white hover:text-white cursor-pointer rounded"
                    @click="resendInvoice(tr)">
                    {{ $t("ResendEmail") }}
                  </a>

                  <vs-button
                    v-if="activeUserInfo.account_role != 2"
                    class="ml-2"
                    color="danger"
                    @click="deleteInvoice(tr.invoice_id, tr.invoice_no)">{{ $t('Delete') }}
                  </vs-button>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage"
                                   v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import moment from "moment";
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'ViewDevices',
  components: {
    DynamicCustomersDropDown,
    Datepicker
  },
  data() {
    return {
      customer: null,
      cPage: 1,
      itemsPerPage: 20,
      isMounted: false,
      fromDate: null,
      toDate: null,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Invoices', i18n: 'Invoices', active: true},
      ],
    }
  },
  computed: {
    invoices() {
      return this.$store.state.invoice.data
    },
    vPage() {
      return this.$store.state.invoice.vPage
    },
    vTotalPages() {
      return this.$store.state.invoice.vTotalPages
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    disabledDates() {
      if (!this.fromDate) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.fromDate,
        from: new Date(),
      }
    },
  },
  props: {
    customerId: {
      type: String,
    }
  },
  methods: {
    deleteInvoice(id, no) {
      let dConfirm = confirm(`Do you want to delete the invoice with ID ${no}`);
      if (dConfirm !== true) {
        return
      }
      let payload = {invoice_id: id, delete: 1}
      this.$vs.loading()
      return this.$store.dispatch('invoice/fetchInvoice', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchInvoices()
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    openInvoice(type, id) {
      if (type == 1) {
        this.$router.push({
          name: 'new-invoice-gross-view', params: {
            invoiceId: id
          }
        })
      } else {
        this.$router.push({
          name: 'new-invoice-credit-note-view', params: {
            invoiceId: id
          }
        })
      }
    },
    onCustomerChanged(c) {
      this.customer = c
      this.fetchInvoices()
    },
    openAddReclamationInvoice() {
      this.$router.push({name: 'invoice-generate-reclamation'})
    },
    openAddInvoice() {
      this.$router.push({name: 'invoice-generate'})
    },

    clearFilterData() {
      this.customer = null
      this.fromDate = null
      this.toDate = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}

      if (this.fromDate) {
        filter.from_date = moment(this.fromDate).format('yyyy-MM-DD')
      }
      if (this.toDate) {
        filter.to_date = moment(this.toDate).format('yyyy-MM-DD')
      }
      return filter
    },
    filterChanged() {
      this.fetchInvoices('0')
    },
    listPageChanged(item) {
      this.fetchInvoices(this.cPage - 1)
    },

    performDownload() {
      this.downloadInvoices(0)
    },
    downloadInvoices(page = '0', filters = {}) {
      const payload = {
        page: page,
        ...this.getFilters(),
      }
      payload.download = 1
      if (this.customer && this.customer != null && this.customer.customer_id) {
        payload.customer_id = this.customer.customer_id
      }
      this.$vs.loading()
      return this.$store.dispatch('invoice/fetchDownloadInvoices', {payload})
        .then((data) => {
          this.$vs.loading.close()


          const hiddenElement = document.createElement('a')
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
          hiddenElement.target = '_blank'
          hiddenElement.download = 'invoices.csv'
          hiddenElement.click()
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    resendInvoice(invoice) {

      let res = confirm(`${this.$t('ResendEmailConfirm')}`);
      if(res) {
        let payload = {invoice_id: invoice.invoice_id, resend_email: 1}
        this.$vs.loading()
        return this.$store.dispatch('invoice/resentMailInvoice', {payload})
          .then((data) => {
            this.$vs.loading.close()
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: data.message || "",
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
          })
          .catch((error) => {
            this.$vs.loading.close()
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: error.response.data.message || error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          })
      }
    },
    fetchInvoices(page = '0', filters = {}) {
      const payload = {
        page: page,
        ...this.getFilters(),
      }
      if (this.customer && this.customer.customer_id) {
        payload.customer_id = this.customer.customer_id
      }
      this.$vs.loading()
      return this.$store.dispatch('invoice/fetchInvoices', {payload})
        .then((data) => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    if (this.customerId) {
      this.customer = {
        customer_id: this.customerId
      }
    }
    this.fetchInvoices()
  },
}
</script>

<style scoped>

</style>
